:root {
    --switchBackground: #9F8B4F;
    --buttonBackgroundUnclicked: #9F8B4F;
    --buttonBackgroundClicked: #796a3e;
    --buttonBorder: 2px solid #9F8B4F;
    --buttonBackgroundHover: #8f7d48;
}

/* Buttons */
.ant-switch-checked{
    background: var(--switchBackground) !important;
}

.ant-btn{
    background: var(--buttonBackgroundUnclicked);
    border-radius: 5px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.ant-btn:hover{
    background: var(--buttonBackgroundHover);
    border-radius: 5px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.ant-btn:active{
    background: var(--buttonBackgroundClicked);
    border: var(--buttonBorder);
    border-radius: 5px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2) !important;
    transform: translateY(2px);
    transition: all 0.1s;
}

.point-of-interest-btn {
    border: var(--buttonBorder);
    background: var(--buttonBackgroundUnclicked);
}

.point-of-interest-btn:hover {
    border: var(--buttonBorder);
}
