
.basic-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding: 0;
    padding-bottom: 8px;
  }

  .basic-header-right-side{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding: 0;
  }
  