.logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .ant-layout-footer{
    padding:0;
  }

  .ant-layout-content {
    padding: 24px
  }

  .result-item{
    justify-content: center;
  }

  .basic-layout-spinner-container{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height:100vh;
  }

  .site-layout .site-layout-background {
    background: rgb(230, 230, 230)
  }