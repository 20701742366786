
.DownloadButton {
    background: #1f9b60 !important;
    color: white !important;
}

.DownloadButton:hover {
    background: #26c177 !important;
}

.DownloadButton:active {
    background: #167447 !important;
}