.svgCanvasOverlay canvas {
    position: absolute;
    z-index: 0;
}

.svgCanvasOverlay svg {
    position: absolute;
    z-index: 10;
}

.chart {
    height: 100%;
    width: 100%;
}