:root {
  --tableHeaderBackground: #2B2B2B;
  --tableHeaderText: #EADBAF;
  --tableRowA: #FFFFFF;
  --tableRowB: #F2F2F2;
  --tableRowHover: #E0E0E0;
  --spinner: #624F16;
  --buttonActiveBackground: #9F8B4F;
  --buttonActiveText: #FFFFFF;
  --buttonInactiveBackground: #FFFFFF;
  --sideMenuBackground: #1B180D;
  --sideMenuActiveBackground: #9F8B4F;
  --sideMenuActiveText: #FFFFFF;
}

.side-menu-logo {
    display: block;
    margin: 0 auto;
}

.ant-menu-submenu.ant-menu-submenu-inline,
.ant-menu-submenu.ant-menu-submenu-vertical{
  padding: 0;}


/* Side Menu this is deprecated css from when we were running antd v4*/
/*
.ant-layout-sider {
  background-color: var(--sideMenuBackground);
}

.ant-layout-sider-children {
  background-color: var(--sideMenuBackground);
}

.ant-menu-submenu .ant-menu-submenu-inline .ant-menu-submenu-active {
  background-color: var(--sideMenuBackground);
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected,
.ant-menu > .ant-layout-sider-trigger:active,
.ant-menu > .ant-layout-sider-trigger:hover {
  color: var(--sideMenuActiveText);
  background-color: var(--sideMenuActiveBackground);
}

.ant-menu > .ant-menu-item,
.ant-menu> .ant-menu-submenu,
.ant-menu > .ant-menu-item-open,
.ant-menu> .ant-menu-submenu-open,
.ant-menu > .ant-layout-sider-trigger {
  background-color: var(--sideMenuBackground);
}

.ant-menu-dark .ant-menu-inline {
  background: var(--sideMenuBackground);
}

.ant-menu .ant-menu-root .ant-menu-inline .ant-menu-dark {
  background-color: var(--sideMenuBackground);
}

.ant-menu-dark {
  background: var(--sideMenuBackground) !important;
}

.ant-layout-sider-trigger {
  background: var(--sideMenuActiveBackground);
  background-color: var(--sideMenuActiveBackground);
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: var(--sideMenuBackground);
}

.ant-menu-submenu-active {
  background-color: var(--sideMenuActiveBackground) !important;
}

.ant-menu-vertical {
  background-color: var(--sideMenuBackground) !important;
}

.ant-tooltip-content {
  color: var(--sideMenuActiveText);
  background-color: var(--sideMenuActiveBackground) !important;
  background: var(--sideMenuActiveBackground) !important;
}
*/

/* Sider */
.custom-sider {
  background-color: var(--sideMenuBackground) !important;
}

/* .custom-sider-children {
  background-color: var(--sideMenuBackground) !important;
} */

.custom-menu-item,
.custom-menu-submenu-title {
  border-radius: 0 !important;
  /* border: 1px solid red !important; */
}

.ant-menu-item,
.ant-menu-submenu-title {
  border-radius: 0 !important;
}

/* Menu item and submenu hover, active, and selected state */
.custom-menu-item:hover,
.custom-menu-submenu:hover,
.custom-menu-item-active,
.custom-menu-submenu-active,
.custom-menu-item-open,
.custom-menu-submenu-open,
.custom-menu-item-selected,
.custom-menu-submenu-selected,
.custom-sider-trigger:active,
.custom-sider-trigger:hover {
  color: var(--sideMenuActiveText) !important;
  background-color: var(--sideMenuActiveBackground) !important;
  border-radius: 0 !important;
  /* border: 1px solid red; */
}

.custom-menu-item,
.custom-menu-submenu,
.custom-menu-item-open,
.custom-menu-submenu-open,
.custom-sider-trigger {
  background-color: var(--sideMenuBackground) !important;
  border-radius: 0px !important;
  max-height: 800px; /* Adjust as needed */
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--sideMenuActiveBackground) var(--sideMenuBackground);
  /* border: 1px solid red !important; */
}

.ant-menu-submenu:hover > .ant-menu-submenu-title,
.ant-menu-submenu-open > .ant-menu-submenu-title,
.ant-menu-submenu-active > .ant-menu-submenu-title {
  background-color: var(--sideMenuActiveBackground) !important;
  color: var(--sideMenuActiveText) !important;
}

.custom-sider-collapsed .ant-menu-submenu:hover > .ant-menu-submenu-title,
.custom-sider-collapsed .ant-menu-submenu-open > .ant-menu-submenu-title {
  background-color: var(--sideMenuActiveBackground) !important;
  color: var(--sideMenuActiveText) !important;
}

.custom-sider-collapsed .ant-menu-submenu:not(:hover):not(.ant-menu-submenu-open) > .ant-menu-submenu-title {
  background-color: var(--sideMenuBackground) !important;
  color: inherit !important;
}

/* Submenu */
.custom-menu-submenu-inline .custom-menu-submenu-active {
  background-color: var(--sideMenuBackground) !important;
}

.custom-menu-submenu-selected > .custom-menu-submenu-title {
  background-color: var(--sideMenuActiveBackground) !important;
  color: var(--sideMenuActiveText) !important;
}

.ant-menu-submenu-popup .ant-menu {
  background-color: var(--sideMenuBackground) !important;
}

.ant-layout-sider.ant-layout-sider-collapsed .ant-menu-submenu-popup .ant-menu {
  background-color: var(--sideMenuBackground) !important;
}

.ant-menu-sub.ant-menu-inline {
  background-color: var(--sideMenuBackground) !important;
}

/* Tooltip */
.custom-tooltip-content {
  color: var(--sideMenuActiveText) !important;
  background-color: var(--sideMenuActiveBackground) !important;
  background: var(--sideMenuActiveBackground) !important;
}

/* Sider trigger */
.ant-layout-sider-trigger {
  background-color: var(--sideMenuActiveBackground) !important;
  color: var(--sideMenuActiveText) !important;
}

/* Menu */
.custom-menu-inline {
  background: var(--sideMenuBackground) !important;
}

.custom-menu-root .custom-menu-inline {
  background-color: var(--sideMenuBackground) !important;
}

.custom-menu-dark {
  background: var(--sideMenuBackground) !important;
}

.custom-menu-inline-sub {
  background-color: var(--sideMenuBackground) !important;
}

.custom-menu-submenu-active {
  background-color: var(--sideMenuActiveBackground) !important;
}

.custom-menu-vertical {
  background-color: var(--sideMenuBackground) !important;
}
