.flex-container {
    display: flex;
}

.flex-child {
    flex: 1;
    
}  

.flex-child:first-child {
    /* margin-right: 20px; */
    
} 

.flex-child-arrow {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px;
    font-size: x-large;
}

.rowC {
    display: flex;
    flex-direction: row;
    width: fit-content;
}