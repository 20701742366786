
.chartRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%; 
    user-select: none;
}

.waterfallChart {
    flex: 1 0 70%;
    width: auto; /* overriding the width: 100% */
}

.sliderContainer {
    flex: 1 0 30%;
    width: auto; /* so it doesn't try to expand to 100% width */
}

.zoneChart, .tfoChart, .modalChart, .brushChart, .chartTightCanvas, .depthChart, .waterfallChart {
    height: 100%;
    width: 100%;
    --chart-margin-left: 40px;
    --chart-margin-top: 20px;
    --chart-margin-right: 60px;
    --chart-margin-bottom: 60px;
    margin-left: 50px;
}


@media (max-width: 800px) {
    .chartRow {
        flex-direction: column;
    }
}