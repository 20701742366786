.container{
    background-color: #fff;
     justify-content: space-between;
     display:flex;
     height: auto;
     line-height:48px;
     padding: 0 25px;
     flex-wrap: wrap;
     align-items: center;
}

.right-side{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}