.highlight {
    animation: pulse 1s;
}

@keyframes pulse {
    0% {
      background-color: #ffffff;
    }
    100% {
      background-color: #70f84f;
    }
  }