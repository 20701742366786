.selector {
    width: 100%;
    height: 100%;
}

ant-list-item:hover {
  box-shadow: 0 0 4px #eee;
}

.Xcentered {
  display: flex;
  /* // centering along main axis - X axis - Horizontal */
  justify-content: center;
  /* // centering along cross axis - Y axis - Vertical */
  align-items: center;
}

.Xcentered button.selected {
  background-color: blue;
}

.range-selector {
  height: 34px;
  margin: 2px;
}

div.ant-space.Xcentered > div > p {
    border-radius: 5px;
    border: darkgray 1px solid;
    padding: 0.25em;
    width: 150px;
    text-align: center;
}

div.ant-space.Xcentered p {
    margin-bottom: 0;
}

.depthSelector > svg > line {
    cursor: ew-resize; 
    stroke: black;
    stroke-width: 4;
    height: calc(100% - 20px);
}

.button-range-selector {
  size: small !important;
  border-radius: 5px;
}

.timeSelector {
  height: calc(100% - 38px);
}

.chart.selector {
    min-width: 150px;
}

.button-range-active-loading {
  background-color: var(--buttonActiveBackground) !important;
  color: var(--buttonActiveText) !important;
  border-radius: 5px !important;
  opacity:0.6 !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
}

.button-range-inactive-loading {
  background-color: var(--buttonInactiveBackground);
  border-radius: 5px !important;
  opacity:0.6 !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
}

.button-range-active-loaded {
  background-color: var(--buttonActiveBackground) !important;
  color: var(--buttonActiveText) !important;
  border-radius: 5px !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
}

.button-range-inactive-loaded {
  background-color: var(--buttonInactiveBackground) !important;
  border-radius: 5px !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
}

.button-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 100px; /* adjust as needed */
  z-index: 1000;
}

.button-group button {
  width: 20px;
  height: 100px;
  margin-bottom: 10px; /* adjust as needed */
}
