/* @import '~antd/dist/antd.css'; */

:root {
  --tableHeaderBackground: #2B2B2B;
  --tableHeaderText: #EADBAF;
  --tableRowA: #FFFFFF;
  --tableRowB: #F2F2F2;
  --tableRowHover: #E0E0E0;
  --spinner: #624F16;
  --buttonActiveBackground: #9F8B4F;
  --buttonActiveText: #FFFFFF;
  --buttonInactiveBackground: #FFFFFF;
  --sideMenuBackground: #1B180D;
  --sideMenuActiveBackground: #9F8B4F;
  --sideMenuActiveText: #FFFFFF;
}

/* Buttons */
.button-online-offline {
  border-radius: 5px;
}

.button-OnlineOffline-active {
  background-color: var(--buttonActiveBackground) !important;
  color: var(--buttonActiveText) !important;
  border-radius: 5px !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
}

.button-OnlineOffline-inactive {
  background-color: var(--buttonInactiveBackground) !important;
  border-radius: 5px !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
}

/* Loading Spinner */
.spinner {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}
.spinner {
    background: rgba(255, 255, 255, 0.08);
}

.ant-spin-dot-item {
  background-color: var(--spinner) !important;
  align-items: center;
}

/* Table */
.currentTempOutsideRange {
    color: red;
    font-size: 90%;
}

.table-row-text-size {
  font-size: 90%;
}

.historicalAverageTemp {
  color: #ABABAB !important;
  font-size: 90%;
}

.centered-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

#table-container .styled-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

#table-container .styled-table th {
  text-align: center;
  background-color: var(--tableHeaderBackground);
  color: var(--tableHeaderText);
  font-weight: bold;
  padding: 8px;
}

#table-container .styled-table tbody tr:nth-child(even) {
  font-size: 90%;
}

#table-container .styled-table tbody tr:nth-child(even) td {
  background-color: var(--tableRowA);
}

#table-container .styled-table tbody tr:nth-child(odd) {
  font-size: 90%;
}

#table-container .styled-table tbody tr:nth-child(odd) td {
  background-color: var(--tableRowB) !important;
}

#table-container .styled-table tbody tr:nth-child(odd) td:first-child,
#table-container .styled-table tbody tr:nth-child(odd) td:nth-child(2) {
  background-color: var(--tableRowB);
}

#table-container .styled-table tbody tr:hover td {
  background-color: var(--tableRowHover) !important;
}

#table-container .styled-table tbody tr {
  transition: background-color 0.3s ease;
}



/* Uncategorized */
/*
.ant-layout-header {
    height: 0px; without this, there is a big blue bar at the top of the homepage
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #001529;
}
*/

