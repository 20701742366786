.summary {
    overflow-y: auto;
    max-height: 70%;
    min-height: 300px;
}

.summary-section {
    border:rgb(46, 148, 28) dashed 2px;
}

.summary-section p {
    line-height: 1.3em;
    margin-bottom: 0px;
}

.summary-line {
    display: flex;
    margin: 0 - 10px;
    background-color: black;
}

.summary-section span {
    line-height: 1.3em;
    margin-bottom: 0px;
    margin-right: 0px;
}

.summary-title {
    display: flex;
}

.summary-section .push-right {
    margin-left: auto;
    margin-right: 5px;
    color:rgb(46, 148, 28)
    
}