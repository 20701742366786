.trace-selection {
    overflow-y: auto;
    max-height: 35%;
}

.trace-selection-line {
    border:rgb(46, 148, 28) dashed 2px;
    display: flex;
    margin: 0 - 10px;
}

.trace-selection-section span {
    line-height: 1.3em;
    margin-bottom: 0px;
}

.trace-selection-section span {
    margin-right: 0px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    pointer-events: none;
}

.trace-selection-section .anticon {
    pointer-events: all;
}

.trace-selection-title {
    text-align: center;
}

.drop-indicator {
    margin-top: 0.1em;
    margin-bottom: 0.1em;
    color: blue;
}

.trace-selection .push-right {
    color: blue;
    margin-left: auto;
}