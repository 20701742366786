:root {
  --tableHeaderBackground: #2B2B2B;
  --tableHeaderText: #EADBAF;
  --tableRowA: #FFFFFF;
  --tableRowB: #F2F2F2;
  --tableRowHover: #E0E0E0;
  --spinner: #624F16;
  --buttonActiveBackground: #9F8B4F;
  --buttonActiveText: #FFFFFF;
  --buttonInactiveBackground: #FFFFFF;
  --sideMenuBackground: #1B180D;
  --sideMenuActiveBackground: #9F8B4F;
  --sideMenuActiveText: #FFFFFF;
}

ant-list-item:hover {
  box-shadow: 0 0 4px #eee;
}

.Xcentered {
  display: flex;
  /* // centering along main axis - X axis - Horizontal */
  justify-content: center;
  /* // centering along cross axis - Y axis - Vertical */
  align-items: center;
}

#petro-container .petro-dropdown .ant-dropdown-menu {
  background-color: var(--sideMenuBackground);
}

#petro-container .petro-dropdown .ant-dropdown-menu-item {
  background-color: var(--sideMenuBackground);
  color: var(--buttonActiveText);
  border-radius: 0%;
}

#petro-container .petro-dropdown .ant-dropdown-menu-item:hover {
  background-color: var(--sideMenuActiveBackground) !important;
}

#petro-container .petro-dropdown .ant-dropdown-menu-item:active {
  background-color: var(--sideMenuActiveBackground);
}

.header-container {
  display: flex;
  align-items: center;
  gap: 3px; /* Adjust this value to set the desired space between elements */
}

.custom-dropdown-menu .ant-dropdown-menu {
  background-color: var(--sideMenuBackground);
  color: var(--buttonActiveText);
}

.custom-dropdown-menu .ant-dropdown-menu-item {
  color: var(--sideMenuActiveText); /* Set the text color */
}
