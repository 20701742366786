.login-form {
  
    border:1px solid #eee;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 0px #ddd;
    background: white;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.login-logo{
  width: 250px;
  margin-bottom:8px;
  border-radius: 4px;
}

.login-panel{
  display: flex;
  flex-direction: column;
  padding:16px;
  align-items: center;
  height: auto;
}

.spinner{
  position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    width: 50px;
    height: 50px;
}

.footer{
  flex-grow: 1;
}