/* doin some colours */
#topBar {
    /* background-color: #333333; */
    background-color: #AAAAAA;
}

#bottomBar {
    background-color: #333333;
}

#TvDTopRow .leftBar {
    /* background-color: rgb(162, 137, 137); */
    background-color: #9F8B4F;
}

#TvDTopRow .rightBar {
    /* background-color: rgb(250, 50, 50); */
    background-color: #9F8B4F;
}

#TvDChartRow .leftBar {
    background-color: #333333;
    color: white;
}

#TvDChartRow .rightBar {
    background-color: #333333;
}

#TvDBottomRow .leftBar {
    background-color: #9F8B4F;
}

#TvDBottomRow .rightBar {
    background-color: #9F8B4F;
}

.zone-picker {
    background-color: #333333;
    color: white;
    height: 48px;
}

.depth-selector {
    height: calc(100% - 48px);
}