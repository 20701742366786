.well-diagram {
    width: '100%';
    margin-top: 30px;
}

.well-section {
    position: absolute;
    height: 100%;
    left: 0; 
}

.depth-labels {
    position: absolute;
    height: 100%;
    top: 100%; 
    font-size: 12px;
    width: 1500px;
}

.depth-labels span {
    position: absolute;
}

/* This increases the height of the tab bar */
.customTabs .ant-tabs-nav-wrap {
    height: 64px;
}
  
/* This changes the background color of the active tab */
.customTabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    background-color: #f5f5f5; 
}

.outer-color-div {
    display: flex;
    align-items: center;
    position: relative;
}
.form-row label {
    margin-right: 10px; /* Space between label-input pairs */
}
.form-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.color-preview {
    /* Removed transform and adjusted margin */
    border: 1px solid #ccc;
    cursor: pointer;
    width: 48px;
    height: 24px;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.color-picker-modal {
    position: absolute;
    z-index: 1000;   
    top: 40px; /* Adjust this value as needed */
    left: 10px; /* Adjust this value as needed */
}

.update-button {
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.add-button {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 15px;
}

.hsbar-bar {
    border-radius: 25px !important;   /* Adjust as needed */
}

/* Custom CSS to ensure scrollbar is always visible in custom Ant Design Select dropdown */
.custom-select-dropdown .ant-select-dropdown-menu {
    overflow-y: scroll !important; /* Ensure the dropdown is always scrollable */
    scrollbar-width: thin; /* For Firefox */
    -ms-overflow-style: -ms-autohiding-scrollbar; /* For Edge and IE */
}

/* For Webkit browsers (Chrome, Safari) */
.custom-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar {
    width: 12px; /* Width of the entire scrollbar */
}

.custom-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: darkgrey; /* Color of the scroll thumb */
    border-radius: 10px; /* Roundness of the scroll thumb */
    border: 3px solid white; /* Creates padding around scroll thumb */
}

